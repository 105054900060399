exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculadora-avancada-jsx": () => import("./../../../src/pages/calculadora-avancada.jsx" /* webpackChunkName: "component---src-pages-calculadora-avancada-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../../../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-politica-privacidade-jsx": () => import("./../../../src/pages/politica-privacidade.jsx" /* webpackChunkName: "component---src-pages-politica-privacidade-jsx" */),
  "component---src-pages-sobre-jsx": () => import("./../../../src/pages/sobre.jsx" /* webpackChunkName: "component---src-pages-sobre-jsx" */),
  "component---src-pages-termos-uso-jsx": () => import("./../../../src/pages/termos-uso.jsx" /* webpackChunkName: "component---src-pages-termos-uso-jsx" */)
}

